import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";

import Hero from "../../sections/camelo/industries/Hero";
import MoreThanSoftware from "../../sections/camelo/MoreThanSoftware";
import SuccessStories from "../../sections/camelo/SuccessStories";
import Fact from "../../sections/camelo/Fact";
import WhatToExpect from "../../sections/camelo/WhatToExpect";
import PeopleAreTalking from "../../sections/camelo/PeopleAreTalking";
import ScreenSizes from "../../sections/camelo/ScreenSizes";
import Features from "../../sections/camelo/Features";
import CTA from "../../sections/camelo/features/CTA";

const CameloHome = ({
  seoTitle,
  metaDescription,
  header,
  subHeader,
  imgUrl,
}) => {
  return (
    <>
      <PageWrapper headerConfig={{ theme: "dark" }}>
        <SEO title={seoTitle} description={metaDescription}></SEO>
        <Hero header={header} subHeader={subHeader} imgUrl={imgUrl} />
        <Features />
        <ScreenSizes></ScreenSizes>
        <PeopleAreTalking />
        <SuccessStories />
        <WhatToExpect />
        <MoreThanSoftware />
        <Fact />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CameloHome;
