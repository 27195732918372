import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/healthcare.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Healthcare Staff Scheduling Software | Camelo"
        metaDescription="Find out what Camelo can do as a flexible scheduling assistant in healthcare industry. Fill shifts faster, ensure staff coverage, and control overtime costs."
        header="A scheduling and communication platform for medical staff."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
