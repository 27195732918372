import React, { useRef } from "react";
import { Link } from "react-scroll";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { navigate } from "gatsby";

const Hero = ({ header, subHeader, imgUrl }) => {
  const emailInput = useRef(null);

  const handleGetStarted = () => {
    window.open(
      `${process.env.SIGNUP_URL}/?workEmail=${emailInput.current.value}`,
      "__blank",
    );
    if (typeof window.gtag_report_conversion === "function") {
      window.gtag_report_conversion(
        `${process.env.SIGNUP_URL}/?workEmail=${emailInput.current.value}`,
      );
    }
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="py-18 position-relative overflow-hidden lg:min-h-vh-100 camelo-hero">
        <div
          className="hero-area bg-image  mx-6 mx-lg-9 rounded-15 h-100 dark-mode-texts"
          css={`
            position: relative;
            z-index: 1;
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="7" md="8">
                <div className="hero-content pr-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-3 mb-16">{header}</h1>
                  <h2
                    className="gr-text-6 mb-0 font-weight-normal"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="900"
                  >
                    {subHeader}
                  </h2>
                  <Link
                    to="elHowItWorks"
                    spy={true}
                    smooth="easeInOutCubic"
                    offset={-80}
                    duration={2000}
                    css={`
                      cursor: pointer;
                    `}
                    className="btn-link with-icon gr-text-color-opacity gr-text-7 font-weight-bold mt-11"
                  >
                    Learn more{" "}
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Link>
                  <InputGroup
                    data-aos="fade-up"
                    data-aos-delay="1200"
                    data-aos-duration="1000"
                    className="mt-16 get-started-black"
                  >
                    <FormControl
                      placeholder="Your work email"
                      aria-label="Your work email"
                      aria-describedby="basic-addon2"
                      ref={emailInput}
                    />
                    <InputGroup.Append>
                      <Button variant="blue" onClick={handleGetStarted}>
                        Get started
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </Col>
              <Col
                lg="4"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end"
              >
                {/* <div */}
                {/*   className="hero-img  z-index-1 mb-md-0" */}
                {/*   data-aos="fade-left" */}
                {/*   data-aos-duration="1000" */}
                {/*   data-aos-delay="500" */}
                {/* > */}
                {/*   {/* <img className="w-100 w-xl-auto" src={imgHero} alt="" /> */}
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </div>

        <div
          css={`
            background-image: url(${imgUrl});
          `}
          className="promo-image"
        ></div>
      </div>
    </>
  );
};

export default Hero;
